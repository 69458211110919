<template>
  <div>
    <MainLayout>
      <template v-slot:toolbar>
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <!--begin::Title-->
          <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Criativo - {{ creative ? creative.name : '' }}</h1>
          <!--end::Title-->
        </div>
        <!--begin::Actions-->
        <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
          <div class="m-0">
            <router-link
              :to="{ name: 'creatives.index' }"
              class="btn btn-sm btn-primary"
            >
              Voltar
            </router-link>
          </div>
          <!--end::Filter menu-->
        </div>
        <!--end::Actions-->
        <!--end::Page title-->
      </template>

      <!-- CONTENT -->
      <!--begin::details View-->
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0">Detalhes</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Action-->
          <!--end::Action-->
        </div>
        <!--begin::Card header-->
        <!--begin::Card body-->
        <div class="card-body p-9">
          <template v-if="loader">
            <div class="py-5">
              <Loader size="big" />
            </div>
          </template>
          <template v-else>
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">ID</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ creative.id }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Row-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Anunciante</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8">
                <router-link
                  :to="{ name: 'advertisers.show', params: { id: creative.advertiser.id } }"
                  class="fw-bold fs-6"
                >
                  {{ creative.advertiser.name }}
                </router-link>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Nome do criativo</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ creative.name }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Criativo</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <a :href="creative.file" target="_blank">
                  <img :src="creative.file" :alt="creative.name" width="150" />
                </a>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">URL</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <a :href="creative.url" class="fw-semibold fs-6" target="_blank">{{ creative.url }}</a>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Formato</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ creative.format }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Layout</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ creative.layout }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Disponível em</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <ul>
                  <li v-if="creative.is_desktop">
                    <span class="fw-semibold text-gray-800 fs-6">Desktop</span>
                  </li>
                  <li v-if="creative.is_mobile">
                    <span class="fw-semibold text-gray-800 fs-6">Mobile</span>
                  </li>
                  <li v-if="creative.is_app">
                    <span class="fw-semibold text-gray-800 fs-6">App</span>
                  </li>
                </ul>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Status</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ creative.status | formatStatus }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">ID Google</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ creative.google_id }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de criação</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ creative.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row mb-7">
              <!--begin::Label-->
              <label class="col-lg-4 fw-semibold text-muted">Data de última atualização</label>
              <!--end::Label-->
              <!--begin::Col-->
              <div class="col-lg-8 fv-row">
                <span class="fw-semibold text-gray-800 fs-6">{{ creative.updated_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</span>
              </div>
              <!--end::Col-->
            </div>
            <!--end::Input group-->
          </template>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::details View-->
      <!-- END CONTENT -->
    </MainLayout>
  </div>
</template>

<script>

import CreativesService from '@/modules/creatives/services/creatives-service'

import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: {
    Loader,
    MainLayout
  },
  metaInfo () {
    return {
      title: this.creative ? this.creative.name : ''
    }
  },
  data () {
    return {
      creative: '',
      loader: false
    }
  },
  created () {
    this.getCreative()
  },
  methods: {
    /**
     * Get creative
     */
    async getCreative () {
      this.loader = true
      try {
        const creative = await CreativesService.getCreative(this.$route.params.id)
        this.creative = creative
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
